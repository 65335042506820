<template>
  <h1>Error 404</h1>
</template>

<script>
export default {
  name: "UrlSyntaxError",
}
</script>


